import React from "react"
import DefaultLayout from "../layouts/DefaultLayout";

const crumbs = [
  {
    title: 'Αρχική',
    url: '/'
  },
  {
    title: 'Γνωρίστε Μας'
  },
  {
    title: 'Η Ιστορία Μας'
  }
];

export default function OurHistory() {
  return <DefaultLayout title='Η Ιστορία Μας' crumbs={crumbs}>
    <div className={'row content-section'}>
      <h3 style={{paddingBottom: '10px'}}>Η Ιστορία Μας</h3>
    </div>
    <div className={'row content-section'}>
      <div className={'col-md'} style={{fontFamily: '"fira sans", sans-serif'}}>
        <p>Το R.E.A.L. Science ξεκίνησε ως μία ιδέα της Ελένης Σινοπούλου (ιδιότητά σου)
          γύρω στο 2018. Μέσω της δικής της μοναδικής διαδρομής σαν Ελληνίδα που
          σπούδασε και τώρα εργάζεται στο εξωτερικό επί πολλά χρόνια, η Ελένη θεώρησε ότι
          υπάρχει αναντιστοιχία ανάμεσα στην πραγματική εμπειρία των σπουδών και της
          επαγγελματικής σταδιοδρομίας και την ασαφή και συχνά στερεοτυπική εικόνα που
          λαμβάνουν τα παιδιά στο σχολείο σαν επαγγελματικό προσανατολισμό. Θα
          μπορούσε να βρεθεί ένας τρόπος να αξιοποιηθεί ο πλούτος γνώσεων και εμπειριών
          των καταρτισμένων Ελλήνων επιστημόνων στην Ελλάδα και στο εξωτερικό προς
          όφελος των Ελλήνων μαθητών και σπουδαστών για τον μελλοντικό τους
          επαγγελματικό προσανατολισμό;</p>

        <p>Η ιδέα έγινε πραγματικότητα το 2019, όταν η Ελένη ήρθε σε επαφή με την ομάδα
          υλοποίησης της πρωτοβουλίας ῾Γέφυρες γνώσης και συνεργασίας᾽ και είχε το
          θάρρος να μοιραστεί μαζί τους το ερώτημα αυτό. Η ομάδα των Γεφυρών χαιρέτισε
          την ιδέα με θέρμη και ενθουσιασμό και επιβεβαίωσε την ανάγκη για μια τέτοια
          δράση. Έτσι λοιπόν η εθελοντική πρωτοβουλία R.E.A.L. Science άρχισε να παίρνει
          σάρκα και οστά μέσα στο 2019.</p>

        <p>Προσδοκία της Ελένης και όλης της ομάδας εθελοντών του R.E.A.L. Science είναι να
          γίνει προσιτή η επιστημονική ειδικότητα και γνώση εξαιρετικά εξειδικευμένων
          επιστημόνων διαθέσιμη στην δευτεροβάθμια καθώς και στην τριτοβάθμια
          εκπαίδευση. Όποια απόσταση στις μέρες μας μπορεί να καταρριφθεί μέσω της
          ψηφιακής τεχνολογίας. Πρώτος στόχος του R.E.A.L. Science είναι να μεταφέρει σε
          κάθε γωνιά της Ελλάδας τις σύγχρονες οπτικές για σπουδές και επαγγελματικό
          προσανατολισμό μέσα από τους ίδιους τους επιστήμονες. Πληροφόρηση και γνώση
          για όλους, όπου κι αν βρίσκονται. Δεύτερος στόχος, που συνδέεται με αυτόν, είναι να
          βγουν oι επιστήμες από τις σελίδες των βιβλίων, και να παρουσιαστούν από τους
          επιστήμονες σε μία νέα, πραγματική και άμεσα εφαρμόσιμη διάσταση. Οι επιστήμες
          δεν είναι μια θεωρία που κάποτε τα παιδιά θα ανακαλύψουν αλλά μια
          πραγματικότητα με συνθήκες που απαιτεί δεξιότητες, συγκεκριμένη νοοτροπία και
          εργασιακό ήθος.</p>

        <p>Μέσα από το R.E.A.L. Science οι εθελοντές επιδιώκουν να προσφέρουν υποστήριξη
          στους εκπαιδευτικούς, δίνοντάς τους την ευκαιρία μέσω της συνεργασίας να
          διαμορφώσουν ένα διαδραστικό και ενδιαφέρον περιβάλλον με σκοπό να
          εμπνεύσουν τους μαθητές και να τους βοηθήσουν να υλοποιήσουν τους στόχους και
          τα όνειρα τους.</p>
      </div>
      <div className={'col-md'}>
      </div>
    </div>
  </DefaultLayout>
}